import React from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { route, routes } from "helpers/routeHelper";
import Message from "model/message";
import PrintAllDocs from "components/Shared/PrintAllDocs";

const OrderDetailedButtons = ({ order }) => {

  const navigate = useNavigate();

  return (<React.Fragment>
    <PrintAllDocs orderNotaryId={order.orderNotaryId} />
    <Button
      color="success"
      className="mav-card-action-btn d-flex justify-content-center"
      onClick={() => navigate(route(routes.view_order_messages, [order.id, Message.CHANNEL_SCHEDULER_NOTARY]))}
    >
      <i className="bx bx-message font-size-20 me-2" />
      Messages
    </Button>
    <Button color="primary" className="mav-card-action-btn d-flex align-items-center justify-content-center" onClick={() => navigate(route(routes.view_order_flow, order.id))}>Start</Button>
  </React.Fragment>)
}

OrderDetailedButtons.propTypes = {
  order: PropTypes.object,
};

export default OrderDetailedButtons;