import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';
import Auth from './auth/reducer';
import Notification from './notification/reducer';
import Profile from './profile/reducer';
import Credential from './credential/reducer';
import Message from './messages/reducer';
import Order from './order/reducer';
import NotaryBid from './notaryBid/reducer';
import Statement from './statement/reducer';

export default combineReducers({
  Layout,
  Auth,
  Notification,
  Profile,
  Credential,
  Message,
  Order,
  NotaryBid,
  Statement,
})
