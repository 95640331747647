import React from "react";
import PropTypes from 'prop-types';
import { Col, Row, Card, CardBody, CardHeader } from "reactstrap";
import { BrowserView, MobileView } from "react-device-detect";
import AdditionalFee from "model/additionalFee";

const AccountingCard = ({ fees }) => {

  /********** STATE **********/
  const totalNotaryFee = fees?.reduce((accum, val) => accum + (Number(val.price) * Number(val.count ?? 1)), 0);
  const notaryType = [AdditionalFee.ID_MAVSIGN_NOTARY, AdditionalFee.ID_EXPRESS_NOTARY, AdditionalFee.ID_SUPER_EXPRESS_NOTARY];

  // check if exist fees with price
  const isPriceNotSet = fees?.some(fee => ((Number(fee.price)) === 0));

  // check if the fee price is 0 and display the Not set message,
  // otherwise display the fee price multiplied with count (count = how many fee exist of that type)
  const checkFeePrice = (price, count) => {
    if (price === 0) return 'Not set';

    return `${Number(price) * Number(count ?? 1)}$`;
  }

  return (<>
    <MobileView>
      <CardHeader className="bg-transparent mb-3 p-0">
        <Row>
          <Col>
            <div className="card-title m-0">Accounting</div>
          </Col>
        </Row>
      </CardHeader>
    </MobileView>
    <Card className="accounting-card mav-card">
      <BrowserView>
        <CardHeader className="bg-transparent pt-3 pb-0">
          <Row>
            <Col>
              <div className="card-title mt-2 mb-0">Accounting</div>
            </Col>
          </Row>
        </CardHeader>
      </BrowserView>
      <CardBody>
        <div className="content-header">
          <div className="d-flex justify-content-between">
            <div className="col-4">No.</div>
            <div className="col-4">Service</div>
            <div className="col-4 text-right">Amount</div>
          </div>
        </div>
        <div className="content-body">
          {fees?.map((item, index) => {
            if (item) {
              return (
                <div key={index} className="body-row d-flex justify-content-between">
                  <div className="col-4">{index + 1}</div>
                  <div className="col-4">{notaryType.includes(item.additionalFeeId) ? 'Service fee' : item.additionalFeeName}</div>
                  <div className="col-4 text-right">{checkFeePrice(Number(item.price), item.count)}</div>
                </div>
              )
            }
          })}
        </div>
        {!isPriceNotSet && <div className="content-footer pt-3">
          <label className="total-cost-label">Total</label>
          <label className="total-cost-value">{`$${totalNotaryFee}` || '0$'}</label>
        </div>}
      </CardBody>
    </Card>
  </>)
}

AccountingCard.propTypes = {
  fees: PropTypes.array,
};

export default AccountingCard;