import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, TabContent, TabPane, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import { useProfile } from "context/profile";
import FormSteps from "../Partial/Form/Steps";
import useFirstRender from "hooks/firstRender";
import { doSaveProfileCleanup } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import useProfileBillingNotComplete from "hooks/profile/profileBillingNotComplete";
import FormShipping from "../Partial/Form/Shipping";
import FormContentShipping from "../Partial/Form/Content/Shipping";

const ProfileSetupShipping = () => {

  // check if the user has completed the billing step
  // and if not, navigate to the previous step
  useProfileBillingNotComplete();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notary } = useProfile();
  const { isNotFirstRender } = useFirstRender();

  /********** STATE **********/

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({});

  // flag signaling that a profile context refresh is in progress
  const [isProfRefInProgress, setIsProfRefInProgress] = useState(false);

  // isSaveInProgress - TRUE if a save request is in progress, FALSE otherwise
  // saved - TRUE if a save was attempted and was successfull, FALSE if it failed, NULL if no save was attempted yet
  const { isSaveInProgress, saved } = useSelector(state => state.Profile.Form);

  /********** EFFECTS **********/

  useEffect(() => {
    return () => {
      // clear redux state on component unmount
      // so the other wizard steps start clean
      dispatch(doSaveProfileCleanup());
    }
  }, []);

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      // changes the in-progress flag
      setIsProfRefInProgress(true);
    }
  }, [saved]);

  // runs when the profile info (from context) changes
  // this happens twice:
  // 1. on the first render (null -> value)
  // 2. when the user clicks NEXT and the profile is updated (old value -> new value)
  // we are interested in #2
  useEffect(() => {
    if (isNotFirstRender) {
      // profile info has been reloaded so it is safe to navigate to the next step
      navigate(route(navRoutes.next));
    }
  }, [notary]);

  /********** OTHER **********/

  /**
   * Returns TRUE if a remote call is in progress
   * We use it to determine whether the submit button should show a loading icon
   * @returns bool
   */
  const isSubmitBusy = () => isSaveInProgress || isProfRefInProgress;

  return <React.Fragment>
    <div className="page-content">
      {!!notary && <React.Fragment>
        <MetaTitle>Profile - Delivery Information</MetaTitle>
        <Container fluid className="profile-wizard">
          <Breadcrumbs breadcrumbItems={breadcrumbs()} />
          <Row>
            <Col xl="12" xxxl="10" xxxxl="8">
              <Card>
                <CardBody>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <FormSteps currentStep="shipping" setNavRoutes={setNavRoutes} />
                    <div className="content clearfix p-0 ps-xl-5">
                      <TabContent activeTab={0} className="body">
                        <TabPane tabId={0}>
                          <FormShipping isSetupRoute>
                            {formik => (<>
                              <div className={`${Object.keys(formik.errors).length > 0 ? "warning  p-2" : "py-2"} mb-4`}>
                                Completing your user profile is mandatory. Please fill out all required fields to proceed.
                              </div>
                              <Row>
                                <Col>
                                  <FormContentShipping isSetupRoute />
                                </Col>
                              </Row>
                              <div className="pb-4">
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      {navRoutes.prev && <Link to={route(navRoutes.prev)} className="btn btn-primary btn-faded mb-2">
                                        <i className="mdi mdi-chevron-left me-1" />
                                        Back
                                      </Link>}
                                      <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting || isSubmitBusy()}>
                                        {isSubmitBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
                                        {!isSubmitBusy() && <i className="mdi mdi-check me-1" />}
                                        Next
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>)}
                          </FormShipping>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
    </div>
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'PROFILE',
}, {
  title: 'Delivery',
}];

export default ProfileSetupShipping;