import { combineReducers } from "redux";

import {
  GET_ORDER_LIST,
  GET_IN_PROGRESS_ORDER_LIST,
  GET_ORDER_LIST_OK,
  GET_ORDER_LIST_ERR,
  DO_ORDER_LIST_CLEANUP,
  SET_VIEW_MODE_TOGGLE,
  SET_ORDER_FILTER_TOGGLE,
  SET_NOTARY_ORDER_FILTER_TOGGLE,

  GET_ORDER,
  GET_ORDER_OK,
  GET_ORDER_ERR,
  DO_ORDER_SINGLE_CLEANUP,

  GET_ORDER_MESSAGES,
  GET_ORDER_MESSAGES_OK,
  GET_ORDER_MESSAGES_ERR,
  GET_LAST_ORDER_MESSAGE,
  GET_LAST_ORDER_MESSAGE_OK,
  GET_LAST_ORDER_MESSAGE_ERR,
  GET_CHAT_MEMBERS,
  GET_CHAT_MEMBERS_OK,
  GET_CHAT_MEMBERS_ERR,

  GET_ORDER_INK_SIGN_DOCS,
  GET_ORDER_INK_SIGN_DOCS_OK,
  GET_ORDER_INK_SIGN_DOCS_ERR,
  DO_ORDER_INK_SIGN_DOCS_CLEANUP,
  RESET_CHANNEL_UNREAD_MESSAGES,

} from "./actionTypes";

import Order from "model/order";
import OrderNotary from "model/orderNotary";
import Message from "model/message";

const defaultListState = {
  orders: [],
  ordersError: null,
  isLoadInProgress: false,
  filterToggle: OrderNotary.VIRTUAL_STATUS_PROCESSING,
  viewModeToggle: Order.UI_GRID_VIEW,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 9,
    page: 1,
    search: null,
    filters: null,
  },
}

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_ORDER_LIST:
      state = {
        ...state,
        isLoadInProgress: action.payload.silentLoad ? false : true,
        listParams: action.payload.params,
      };
      break;
    case GET_IN_PROGRESS_ORDER_LIST:
      state = {
        ...state,
        isLoadInProgress: true,
        ordersError: null,
      };
      break;
    case GET_ORDER_LIST_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        orders: action.payload.response.orders,
        totalCount: action.payload.response.totalCount,
      };
      break;
    case GET_ORDER_LIST_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        ordersError: action.payload.error,
      };
      break;
    case DO_ORDER_LIST_CLEANUP:
      state = {
        ...defaultListState,
        // persist UI toggles
        filterToggle: state.filterToggle,
        viewModeToggle: state.viewModeToggle,
      };
      break;
    case SET_ORDER_FILTER_TOGGLE:
      state = {
        ...state,
        filterToggle: action.payload.status,
      };
      break;
    case SET_VIEW_MODE_TOGGLE:
      state = {
        ...state,
        viewModeToggle: action.payload.mode,
      };
      break;
    case SET_NOTARY_ORDER_FILTER_TOGGLE:
      state = {
        ...defaultListState, // reset pagination params and rest of state
        filterToggle: action.payload.status,
      };
      break;
  }
  return state;
}

/********** SINGLE **********/

const defaultSingleState = {
  order: null,
  orderError: null,
  isLoadInProgress: false,
  docs: [],
};

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER:
      state = {
        ...state,
        orderError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_OK:
      state = {
        ...state,
        order: action.payload.response.order,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_ERR:
      state = {
        ...state,
        order: null,
        orderError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_SINGLE_CLEANUP:
      state = { ...defaultSingleState };
      break
  }
  return state;
}

/********** MESSAGES **********/

const defaultMessagesState = {
  messages: {
    [Message.CHANNEL_SCHEDULER_NOTARY]: [],
    [Message.CHANNEL_NOTARY_CUSTOMER]: []
  },
  unreadMessagesCount: {
    [Message.CHANNEL_SCHEDULER_NOTARY]: [],
    [Message.CHANNEL_NOTARY_CUSTOMER]: []
  },
  messagesError: null,
  isLoadInProgress: false,
  lastMessage: null,
  isLoadMessageInProgress: false,
  lastMessageError: null,
  members: {
    [Message.CHANNEL_SCHEDULER_NOTARY]: [],
    [Message.CHANNEL_NOTARY_CUSTOMER]: []
  },
  membersError: null,
  isLoadMembersInProgress: false,
}

const Messages = (state = defaultMessagesState, action) => {
  switch (action.type) {
    case GET_ORDER_MESSAGES:
      state = {
        ...state,
        isLoadInProgress: true,
        messagesError: null,
      };
      break
    case GET_ORDER_MESSAGES_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        messages: {
          ...state.messages,
          [action.payload.channelId]: action.payload.response.messages
        },
        unreadMessagesCount: {
          ...state.unreadMessagesCount,
          [action.payload.channelId]: action.payload.response.unreadMessagesCount
        }
      }
      break
    case GET_ORDER_MESSAGES_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        messagesError: action.payload.error
      }
      break
    case GET_LAST_ORDER_MESSAGE:
      state = {
        ...state,
        lastMessage: null,
        isLoadMessageInProgress: true,
        lastMessageError: null,
      };
      break
    case GET_LAST_ORDER_MESSAGE_OK:
      state = {
        ...state,
        isLoadMessageInProgress: false,
        lastMessage: action.payload.response,
      };
      break
    case GET_LAST_ORDER_MESSAGE_ERR:
      state = {
        ...state,
        isLoadMessageInProgress: false,
        lastMessage: null,
        lastMessageError: action.payload.error,
      };
      break
    case GET_CHAT_MEMBERS:
      state = {
        ...state,
        isLoadMembersInProgress: true,
        membersError: null,
      };
      break
    case GET_CHAT_MEMBERS_OK:
      state = {
        ...state,
        isLoadMembersInProgress: false,
        members: {
          ...state.members,
          [action.payload.channelId]: action.payload.response.participants,
        }
      }
      break
    case GET_CHAT_MEMBERS_ERR:
      state = {
        ...state,
        isLoadMembersInProgress: false,
        membersError: action.payload.error
      }
      break
    case RESET_CHANNEL_UNREAD_MESSAGES:
      state = {
        ...state,
        unreadMessagesCount: {
          ...state.unreadMessagesCount,
          [action.payload.channelId]: 0
        }
      }
      break
  }
  return state;
}

/********** INK-SIGN DOCS **********/

const defaultInkSignDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const InkSignDocs = (state = defaultInkSignDocsState, action) => {
  switch (action.type) {
    case GET_ORDER_INK_SIGN_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_INK_SIGN_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_INK_SIGN_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ORDER_INK_SIGN_DOCS_CLEANUP:
      state = { ...defaultInkSignDocsState };
      break
  }
  return state;
}


export default combineReducers({
  List,
  Single,
  Messages,
  InkSignDocs,
});