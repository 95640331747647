import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import UsStates from "constants/usState";
import { useFormikContext } from "formik";
import { getAddressComponents, showError, showSuccess, toSelectOptions, distanceServedOptions } from "helpers/utilHelper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { resetSaveProfileActionFlag } from "store/actions";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import classnames from "classnames";
import TextareaAutosize from "react-textarea-autosize";

const FormContentShipping = ({ isSetupRoute }) => {

  const dispatch = useDispatch();
  const { refreshProfile } = useProfile();

  // consume formik instance from context
  const formik = useFormikContext();

  // saved - TRUE if a save was attempted and was successful, FALSE if it failed, NULL if no save was attempted yet
  const { saved } = useSelector(state => state.Profile.Form);
  // flags if the user opted to use the billing address for delivery or not
  const isSameAsBillingAddress = JSON.parse(localStorage.getItem("isSameAddress"));

  /********** EFFECTS **********/

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Delivery info saved");
      refreshProfile();
    } else if (saved === false) {
      showError("Unable to save delivery info");
      formik.setSubmitting(false);
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag("saved"));
    }
  }, [saved]);

  // runs whenever `isSameAsBillingAddress` flag changes
  // which is triggered by the user in the Billing tab
  // if the user is in the setup wizard and `use same address` has been checked, prefill the shipping address details with the ones from billing
  useEffect(() => {
    if (isSameAsBillingAddress && isSetupRoute) {
      formik.setFieldValue("shippingAddress", formik.values.billingAddress);
      formik.setFieldValue("shippingZip", formik.values.billingZip);
      formik.setFieldValue("shippingCity", formik.values.billingCity);
      formik.setFieldValue("shippingState", formik.values.billingState);
      formik.setFieldValue("shippingLatitude", formik.values.billingLatitude);
      formik.setFieldValue("shippingLongitude", formik.values.billingLongitude);
    }
  }, [isSameAsBillingAddress]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  const fillInAddress = place => {
    // parse the `place` object we receive from Google Places Autocomplete
    const addressComponents = getAddressComponents(place);
    // fill in the form with the correlated address components
    formik.setFieldValue("shippingZip", addressComponents.zip);
    formik.setFieldValue("shippingCity", addressComponents.city);
    formik.setFieldValue("shippingState", addressComponents.state);
    formik.setFieldValue("shippingAddress", addressComponents.address);
    // based on the location selected by the user in the dropdown, we are setting the map coordinates
    // these are very important pieces of information, because they are used in calculating the distance between notary and customer
    // and selecting notaries for the bidding
    formik.setFieldValue("shippingLatitude", place.geometry.location.lat());
    formik.setFieldValue("shippingLongitude", place.geometry.location.lng());
  };

  return <Form autoComplete="force-disable-autofill">
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Document Delivery Address*</Label>
      <Col sm={8}>
        <PlacesAutocomplete
          type="text"
          className={classnames("form-control", { "is-invalid": !!(formik.errors.shippingAddress || formik.errors.shippingLatitude || formik.errors.shippingLongitude) })}
          placeholder="ex. 2273 Muldrow Dr"
          name="shippingAddress"
          onChange={formik.handleChange}
          onPlaceChanged={fillInAddress}
          onFocus={(e, fieldName) => { onFieldFocused(e, fieldName); onFieldFocused(e, "shippingLatitude"); onFieldFocused(e, "shippingLongitude"); }}
          value={formik.values.shippingAddress}
        />
        {!!formik.errors.shippingAddress && <FormFeedback type="invalid">{formik.errors.shippingAddress}</FormFeedback>}
        {(!!formik.errors.shippingLatitude || !!formik.errors.shippingLongitude) && <FormFeedback type="invalid">Please select a valid address</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Delivery State*</Label>
      <Col sm={8}>
        <Select
          classNamePrefix="select2-selection"
          name="shippingState"
          options={usStates}
          onChange={selected => formik.setFieldValue("shippingState", selected.value)}
          onFocus={e => onFieldFocused(e, "shippingState")}
          value={usStates.find(option => option.value === formik.values.shippingState)}
          className={!!formik.errors.shippingState && "is-invalid"} />
        {!!formik.errors.shippingState && <FormFeedback type="invalid">{formik.errors.shippingState}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Delivery City*</Label>
      <Col sm={8}>
        <Input
          type="text"
          className="form-control"
          placeholder="ex. New Jersey"
          autoComplete="force-disable-autofill"
          onChange={event => formik.setFieldValue("shippingCity", event.target.value)}
          onFocus={onFieldFocused}
          value={formik.values.shippingCity}
          invalid={!!formik.errors.shippingCity}
        />
        {!!formik.errors.shippingCity && <FormFeedback type="invalid">{formik.errors.shippingCity}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Delivery ZIP*</Label>
      <Col sm={8}>
        <Input
          type="text"
          className="form-control"
          placeholder="ex. 90017"
          autoComplete="force-disable-autofill"
          onChange={event => formik.setFieldValue("shippingZip", event.target.value)}
          onFocus={onFieldFocused}
          value={formik.values.shippingZip}
          invalid={!!formik.errors.shippingZip}
        />
        {!!formik.errors.shippingZip && <FormFeedback type="invalid">{formik.errors.shippingZip}</FormFeedback>}
      </Col>
    </Row>
    {
      !isSetupRoute &&
      <Row className="mb-4 align-items-center">
        <Label className="col-sm-4 col-form-label word-wrap-normal">Distance Willing to Travel*</Label>
        <Col sm={8}>
          <Select
            classNamePrefix="select2-selection"
            name="distanceServed"
            options={distanceServedOptions}
            onChange={selected => formik.setFieldValue("distanceServed", selected.value)}
            onFocus={onFieldFocused}
            value={distanceServedOptions.find(option => option.value === formik.values.distanceServed)}
            className={!!formik.errors.distanceServed && "is-invalid"} />
          {!!formik.errors.distanceServed && <FormFeedback type="invalid">{formik.errors.distanceServed}</FormFeedback>}
        </Col>
      </Row>
    }
    <Row className="mb-4 align-items-center">
      <Label className="col-sm-4 col-form-label word-wrap-normal">Cities / Counties Served</Label>
      <Col sm={8}>
        <TextareaAutosize maxRows={15} className={classnames("form-control", { "is-invalid": !!formik.errors.citiesCountiesServed })} name="citiesCountiesServed" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.citiesCountiesServed} />
        {!!formik.errors.citiesCountiesServed && <FormFeedback type="invalid">{formik.errors.citiesCountiesServed}</FormFeedback>}
      </Col>
    </Row>
  </Form>
}

FormContentShipping.propTypes = {
  isSetupRoute: PropTypes.bool,
}

const usStates = toSelectOptions(UsStates);

export default FormContentShipping;