import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Alert, CardFooter, Button, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
import SpinnerChase from "components/Shared/SpinnerChase";
import { route, routes } from "helpers/routeHelper";
import { getInitialsFromName } from "../../../../helpers/utilHelper";
import UserAvatar from "../../../../components/Shared/UserAvatar";
import { useSocketOn, useSubscribeToOrderMessages } from "../../../../hooks/socket";
import socketEvent from "../../../../constants/socketEvent";
import Message from "../../../../model/message";
import { getChats } from "../../../../helpers/backendHelper";

const ChatsDesktop = () => {

  /********** OTHERS **********/
  const [chats, setChats] = useState([]);
  const [chatsError, setChatsError] = useState();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  const onGetChats = () => {
    setIsLoadInProgress(true);
    getChats()
      .then(resp => setChats(resp.chats))
      .catch((err) => {
        setChatsError(err);
        showError("Unable to load data");
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(() => {
    onGetChats()
  }, []);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    onGetChats()
  }, []);

  return <>
    <Card className="messages-card">
      <CardTitle>Chats</CardTitle>
      <CardBody className="p-0 messages-table">
        <Table className="table">
          <Thead>
            <Tr>
              <Th>Customer Name</Th>
              <Th>Message</Th>
              <Th>Channel</Th>
              <Th className="text-right pe-5">Actions</Th>
            </Tr>
          </Thead>
          <tbody>
            {!isLoadInProgress && !chatsError && chats.map((entry, index) => (
              <Tr key={index}>
                <Td><Link to={route(routes.view_order, entry.orderId)}>{entry.senderName}</Link></Td>
                <Td style={{ maxWidth: 250 }} className="message-content">
                  {entry.contentType === Message.CONTENT_TYPE_TEXT && entry.content}
                  {entry.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex"><i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span></div>}
                </Td>
                <td>
                  <span id={`channel-${index}`} className="h-100 d-inline-block">
                    {<UserAvatar initials={getInitialsFromName(Message.getChannelName(entry.channel))} size="sm" className="mt-n2" />}
                  </span>
                  <UncontrolledTooltip
                    placement="top"
                    target={`channel-${index}`}
                  >
                    {Message.getChannelName(entry.channel)}
                  </UncontrolledTooltip>
                </td>
                <Td className="text-right">
                  <Link to={route(routes.view_order_messages, [entry.orderId, entry.channel])}>
                    <span className={`badge badge-lg rounded-pill bg-primary`}>Go to Chats</span>
                  </Link>
                </Td>
              </Tr>))}
            {!isLoadInProgress && !chatsError && chats.length === 0 && <Tr>
              <Td className="table-empty" colSpan="4">No messages found</Td>
            </Tr>}
            {isLoadInProgress && <Tr>
              <Td className="table-empty" colSpan="4">
                <SpinnerChase />
              </Td>
            </Tr>}
            {chatsError && <Tr>
              <Td className="table-empty" colSpan="4">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load messages
                </Alert>
              </Td>
            </Tr>}
          </tbody>
        </Table>
      </CardBody>
      <CardFooter className="bg-transparent d-flex justify-content-end pt-0">
        <a href={route(routes.list_messages)}>
          <Button color="light" className="text-primary">
            View All <i className="bx bx-right-arrow-alt" />
          </Button>
        </a>
      </CardFooter>
    </Card>
  </>
}

export default ChatsDesktop;