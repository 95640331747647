import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { InfoWindow, Map, Marker, Circle } from "google-maps-react";
import { useGoogleApi } from "context/google";

// set a default center, otherwise the map is centered in the ocean
const defaultCenter = {
  lat: 33.59773919487583,
  lng: -117.6563644,
}

const GoogleMapsLocation = props => {
  // Map Ref for fitting bounds
  const mapRef = useRef(null);

  const { position, address, radius } = props;

  const { google } = useGoogleApi();

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const onMarkerClicked = (props, marker) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  }

  const onMapClicked = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
  }

  // Setting the map ref when map loads
  const onMapReady = (mapProps, map) => {
    mapRef.current = map;
  };

  // Function to fit the bounds when radius changes
  const fitBounds = () => {
    // Creating Lat Lng Bounds
    const bounds = new google.maps.LatLngBounds();
    // Extending them with the current position
    bounds.extend(new google.maps.LatLng(position.lat, position.lng));

    // Adjust the bounds based on the radius
    if (radius) {
      const center = new google.maps.LatLng(position.lat, position.lng);
      const circle = new google.maps.Circle({
        center: center,
        radius: radius,
      });
      bounds.union(circle.getBounds()); // Union circle bounds with the map bounds
    }

    // Fit the map to the calculated bounds
    mapRef.current && mapRef.current.fitBounds(bounds);
  };

  // Fit bounds whenever radius changes
  useEffect(() => {
    if (mapRef.current) {
      fitBounds();
    }
  }, [radius, mapRef.current]);

  return (
    <div
      id="gmaps-markers"
      className="gmaps"
      style={{ position: "relative" }}
    >
      <Map
        google={google}
        style={{ width: "100%", height: "100%" }}
        zoom={14}
        onClick={onMapClicked}
        center={position || defaultCenter}
        onReady={onMapReady}
      >
        <Marker
          title={"Current location"}
          name={address}
          onClick={onMarkerClicked}
          position={position}
        />
        {radius > 0 &&
          <Circle
            center={position}
            radius={radius} // radius must be in meters
          />
        }
        <InfoWindow visible={showInfoWindow} marker={activeMarker}>
          <div>
            <h5 className="mt-2">{selectedPlace.name}</h5>
          </div>
        </InfoWindow>
      </Map>
    </div>
  )
}

GoogleMapsLocation.propTypes = {
  position: PropTypes.object,
  address: PropTypes.string,
  radius: PropTypes.number
}

export default GoogleMapsLocation;