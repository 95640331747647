export default class OrderNotary {

  static STATUS_ORDER_INCOMPLETE = -1;
  static STATUS_ASSIGNED = 0;
  static STATUS_DOCS_RECEIVED = 1;
  static STATUS_APPOINTMENT_SET = 2;
  static STATUS_APPOINTMENT_DECLINED = 3;
  static STATUS_APPOINTMENT_ACCEPTED = 4;
  static STATUS_DOCS_PRINTED = 5;
  static STATUS_DOCS_UPLOADED = 6;
  static STATUS_ORDER_COMPLETE = 7;

  static VIRTUAL_STATUS_INCOMPLETE = -1;
  static VIRTUAL_STATUS_PROCESSING = 0;
  static VIRTUAL_STATUS_COMPLETE = 1;

  // Order notary info messages
  static MESSAGE_MUST_PREVIEW_DOCS = `<b>*Important* </b>All signed dealer documents must be uploaded in Step 4. Please see the instructions below.`;
  static MESSAGE_TIME_SAVE_TIP = `Secure, Time-Saving Tip: Immediately following the singing, use the real-time image upload option below to eliminate customer images being stored on your device and eliminate extra time scanning and uploading after the fact.`;
  static MESSAGE_ADD_ADDITIONAL_DOCS = `Please upload all signed dealer documents here. PDF files are accepted. You will not be able to proceed to the next step until the AOI, DL and all signed documents are uploaded.`;
  static MESSAGE_DELIVERY_OPTION_UPLOAD = `This is an Upload order that will require you to print the documents that are to be signed by the customer. The documents will be easily accessible in the order.`;
  static MESSAGE_RETURN_TO_DEALERSHIP_MESSAGE_ONE = `Almost done! Please click here to select all documents below that have been shipped back to the dealership.`;
  static MESSAGE_RETURN_TO_DEALERSHIP_MESSAGE_TWO = `Please mark each document below as &quot;Document Shipped&quot; for the documents you witnessed signatures for. You have the option to select all. This feature is to protect our notaries in the event the dealership has a document listed that is not in the signed package.`;
  static MESSAGE_MUST_CONFIRM_STEPS_ABOVE = `You must CONFIRM each step above to complete the next step in the order process!`;
  static MESSAGE_MUST_CONFIRM_STEPS = `All 5 steps must be confirmed to move forward.`;

  static getStatusMap() {
    return {
      [this.STATUS_ASSIGNED]: 'Assigned',
      [this.STATUS_DOCS_RECEIVED]: 'Docs Received',
      [this.STATUS_APPOINTMENT_SET]: 'Appointment Sent',
      [this.STATUS_APPOINTMENT_DECLINED]: 'Appointment Declined',
      [this.STATUS_APPOINTMENT_ACCEPTED]: 'Appointment Accepted',
      [this.STATUS_DOCS_PRINTED]: 'Docs Printed',
      [this.STATUS_DOCS_UPLOADED]: 'Docs Uploaded',
      [this.STATUS_ORDER_COMPLETE]: 'Completed',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }

  static getVirtualStatusMap() {
    return {
      [this.VIRTUAL_STATUS_PROCESSING]: 'Processing',
      [this.VIRTUAL_STATUS_COMPLETE]: 'Completed',
      [this.VIRTUAL_STATUS_INCOMPLETE]: 'Not Completed',
    };
  }

  static getVirtualStatusName(id) {
    return this.getVirtualStatusMap()[id];
  }

  static getVirtualStatus(id) {
    if (id < this.STATUS_ASSIGNED)
      return this.VIRTUAL_STATUS_INCOMPLETE;
    else if (id < this.STATUS_ORDER_COMPLETE)
      return this.VIRTUAL_STATUS_PROCESSING;
    else return this.VIRTUAL_STATUS_COMPLETE;
  }

  static getVirtualStatusColor(id) {
    switch (id) {
      case this.VIRTUAL_STATUS_PROCESSING:
      case this.VIRTUAL_STATUS_INCOMPLETE:
        return 'warning';
      case this.VIRTUAL_STATUS_COMPLETE:
        return 'success';
      default:
        return 'dark';
    }
  }

  static getStatusMapColor() {
    return {
      [this.STATUS_ORDER_INCOMPLETE]: 'order-incomplete',
      [this.STATUS_ASSIGNED]: 'assigned-notary',
      [this.STATUS_DOCS_RECEIVED]: 'docs-received',
      [this.STATUS_APPOINTMENT_SET]: 'appointment-sent',
      [this.STATUS_APPOINTMENT_DECLINED]: 'appointment-declined',
      [this.STATUS_APPOINTMENT_ACCEPTED]: 'appointment-accepted',
      [this.STATUS_DOCS_PRINTED]: 'docs-printed',
      [this.STATUS_DOCS_UPLOADED]: 'docs-uploaded',
      [this.STATUS_ORDER_COMPLETE]: 'completed-notary',
    };
  }

  static getStatusColor(id) {
    return this.getStatusMapColor()[id];
  }
}