import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Preloader from "components/Shared/Preloader";
import { getProfile } from "store/actions";

const ProfileContext = React.createContext();

const ProfileProvider = props => {

  const dispatch = useDispatch();

  const { notary, isLoadInProgress } = useSelector(state => state.Profile.Single);

  // returns TRUE if the notary profile is not yet completely set up
  // we use the 'distanceServed' field to check that because it is added in the last wizard step
  // so we can assume that if the user has reached that far, he has completed the entire profile
  // (note that this field accepts a value of 0 so we must check for NULL)
  // also we use the 'termsAcceptedTs' field to check that because imported notaries have all their fields completed
  // (including the 'distanceServed')
  // but they still need to agree to terms
  const isProfileSetupRequired = () => !notary.termsAcceptedTs || notary.distanceServed === null;

  useEffect(() => {
    // fetch subscription details from backend
    refreshProfile();
  }, []);

  const refreshProfile = () => dispatch(getProfile());

  // wait until we fetch subscription data
  if (notary !== undefined) {
    return <ProfileContext.Provider value={{
      notary, isLoadInProgress, isProfileSetupRequired, refreshProfile,
    }} {...props} />
  }
  // until then show a preloader
  return <Preloader />
}

// helper hook that makes context data available
export const useProfile = () => React.useContext(ProfileContext);

export default ProfileProvider;