import React, { useEffect, useRef } from "react"
import { useLocation } from 'react-router-dom';

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"

import { route, routes } from "helpers/routeHelper"

const SidebarContent = () => {

  const location = useLocation();

  const ref = useRef();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        let activeFor = items[i].getAttribute('data-active-for');
        let isActiveFor = false;
        if (activeFor) {
          activeFor = activeFor.split('~');
          isActiveFor = activeFor.some(function (pattern) {
            var re = new RegExp(pattern);
            return re.test(pathName);
          });
        }
        if (pathName === items[i].pathname || isActiveFor) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.href])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    if (item.classList.contains('active')) {
      return;
    }
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="h-100">
          <ul className="metismenu list-unstyled d-flex flex-column h-100" id="side-menu">
            <li className="menu-title">Menu</li>

            <li>
              <Link to={route(routes.home)} data-active-for="^\/dashboard($|\/(.*)+)">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li>
              <Link to={route(routes.list_notary_bids)} data-active-for="^\/notary-bid($|\/(.*)+)">
                <i className="bx bx-user-pin"></i>
                <span>My Leads</span>
              </Link>
            </li>

            <li>
              <Link to={route(routes.list_orders)} data-active-for="^\/order($|\/(.*)+)">
                <i className="mdi mdi-shopping"></i>
                <span>My Orders</span>
              </Link>
            </li>

            <li>
              <Link to={route(routes.view_profile)} data-active-for="^\/profile($|\/(.*)+)">
                <i className="bx bx-user"></i>
                <span>Notary Profile</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-calculator"></i>
                <span>Accounting</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to={route(routes.list_statements)} data-active-for="^\/statement($|\/(.*)+)">Statements</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to={route(routes.view_documents)} data-active-for="^\/documents($|\/(.*)+)" state={{ isRedirect: true }}>
                <i className="bx bx-file"></i>
                <span>Documents</span>
              </Link>
            </li>

            <li>
              <Link to={route(routes.list_messages)} data-active-for="^\/message($|\/(.*)+)">
                <i className="mdi mdi-message-text"></i>
                <span>Messages</span>
              </Link>
            </li>

            <li>
              <Link to={route(routes.list_help)} data-active-for="^\/help($|\/(.*)+)">
                <i className="mdi mdi-help-circle-outline"></i>
                <span>Help section</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent;
