import React, { useState } from "react";
import PropTypes from "prop-types";
import DocPageDesktop from "pages/Order/Partial/Doc/PageDesktop";
import DocPageMobile from "pages/Order/Partial/Doc/PageMobile";
import { Gallery } from "react-photoswipe-gallery";
import { BrowserView, MobileView } from "react-device-detect";
import signatureIcon from "assets/images/signature-icon.svg";
import OrderDoc from "model/orderDoc";
import { UncontrolledTooltip, Button, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import Confirmation from "components/Shared/Confirmation";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import { deleteOrderDocPdf } from "helpers/backendHelper";

const SectionNotaryDocs = ({ order, docs, refreshHandler, disabled, setHasInProgressUploads }) => {

  const [isRemovePdfConfirmationVisible, setIsRemovePdfConfirmationVisible] = useState(false);
  const [isDeletingPdf, setIsDeletingPdf] = useState(false);
  const [docToRemovePdf, setDocToRemovePdf] = useState(null);

  const removePdfFile = async () => {
    if (!docToRemovePdf) {
      return;
    }
    setIsDeletingPdf(true);
    try {
      await deleteOrderDocPdf(docToRemovePdf.id);
      refreshHandler();
      showSuccess('The pdf file has been deleted');
    } catch (err) {
      showError("Unable to remove pdf file");
    } finally {
      setIsDeletingPdf(false);
    }
  }

  return (
    <React.Fragment>
      {!!docs?.length && docs.sort((a, b) => b.isNotarizationRequired - a.isNotarizationRequired).map(doc => {
        const viewUrl = getBeUrl(`/order-doc/${doc.id}/pdf`);
        return <div key={doc.id} className="my-4">
          <React.Fragment>
            {/* Desktop Content */}
            <BrowserView>
              <div className="doc-item justify-content-between">
                <div className="d-inline-flex">
                  <div className="doc-item-title">
                    {doc.name}
                  </div>
                  {!!doc.numOfInitials && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
                    {`${doc.numOfInitials} Initial${doc.numOfInitials > 1 ? "s" : ""}`} <i className="mdi mdi-caps-lock ms-1" />
                  </div>}
                  {!!doc.numOfSignatures && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
                    {`${doc.numOfSignatures} Signature${doc.numOfSignatures > 1 ? "s" : ""}`} <img alt="signatures" src={signatureIcon} className="ms-1" />
                  </div>}
                  {!!doc.numOfPages && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
                    {`${doc.numOfPages}`} <i className="mdi mdi-file-multiple-outline ms-1" />
                  </div>}
                  {doc.isStdDoc && <div className="badge badge-lg rounded-pill bg-warning doc-item-badge mt-0">Required</div>}
                  {doc.status == OrderDoc.STATUS_REJECTED && <React.Fragment>
                    <div className="badge badge-lg rounded-pill bg-danger doc-item-badge mt-0" id={'doc-rejected-' + doc.id}>Rejected</div>
                    {!!doc.rejectionReason && <UncontrolledTooltip placement="top" target={'doc-rejected-' + doc.id}>{doc.rejectionReason}</UncontrolledTooltip>}
                  </React.Fragment>}
                  {doc.status == OrderDoc.STATUS_COMPLETE && <div className="badge badge-lg rounded-pill bg-success doc-item-badge mt-0" id={'doc-accepted-' + doc.id}>Accepted</div>}
                  {doc.isCapturedAsPdf && <a href={viewUrl} target="_blank" rel="noreferrer" className="p-0 mt-n2 mb-n2" style={{ color: 'inherit' }}>
                    <i className="mdi mdi-file-pdf-outline ms-1 captured-as-pdf-icon" title="Document was captured as pdf"></i>
                  </a>}
                </div>
                {doc.isCapturedAsPdf && <Button color="secondary" className="square-icon-btn btn btn-danger" title="Remove Pdf file" onClick={() => {
                  setDocToRemovePdf(doc);
                  setIsRemovePdfConfirmationVisible(true);
                }} disabled={isDeletingPdf}>
                  <i className="mdi mdi-trash-can-outline" />
                </Button>}
              </div>
              {!doc.isCapturedAsPdf && <div className="doc-pages">
                <Gallery id="ink-docs" options={{ mainClass: "ink-sign-gallery" }}>
                  {Array.from({ length: doc.numOfPages }).map((page, index) => (
                    <React.Fragment key={index}>
                      <BrowserView>
                        <DocPageDesktop
                          docId={doc.id}
                          isPageUploaded={!!doc.pages?.[index + 1]}
                          pageNum={index + 1}
                          finishedHandler={refreshHandler}
                          disabled={disabled}
                          pages={doc.pages}
                          setHasInProgressUploads={setHasInProgressUploads}
                        />
                      </BrowserView>

                      <MobileView className="doc-page-mobile-wrapper">
                        <DocPageMobile
                          docId={doc.id}
                          docName={doc.name}
                          isPageUploaded={!!doc.pages?.[index + 1]}
                          pageNum={index + 1}
                          finishedHandler={refreshHandler}
                          disabled={disabled}
                          pages={doc.pages}
                          setHasInProgressUploads={setHasInProgressUploads}
                        />
                      </MobileView>
                    </React.Fragment>
                  ))}
                </Gallery>
              </div>}
            </BrowserView>

            {/* Mobile Content */}
            <MobileView className="doc-item-mobile">
              <div className="doc-item-mobile-header">
                <h6 className="accordion-title mb-2">{doc.name}</h6>
                <div className="d-flex align-items-center mb-3">
                  {!!doc.numOfInitials && <div className="badge badge-lg rounded-pill doc-item-badge mt-2">
                    {`${doc.numOfInitials} Initial${doc.numOfInitials > 1 ? "s" : ""}`} <i className="mdi mdi-caps-lock ms-1" />
                  </div>}
                  {!!doc.numOfSignatures && <div className="badge badge-lg rounded-pill doc-item-badge">
                    {`${doc.numOfSignatures} Signature${doc.numOfSignatures > 1 ? "s" : ""}`} <img alt="signatures" src={signatureIcon} className="ms-1" />
                  </div>}
                  {!!doc.numOfPages && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
                    {`${doc.numOfPages}`} <i className="mdi mdi-file-multiple-outline ms-1" />
                  </div>}
                  {doc.isStdDoc && <div className="badge badge-lg rounded-pill bg-warning doc-item-badge mt-0">Required</div>}
                  {doc.status == OrderDoc.STATUS_REJECTED && <React.Fragment>
                    <div className="badge badge-lg rounded-pill bg-danger doc-item-badge mt-0" id={'doc-rejected-' + doc.id}>Rejected</div>
                    {!!doc.rejectionReason && <UncontrolledTooltip placement="top" target={'doc-rejected-' + doc.id}>{doc.rejectionReason}</UncontrolledTooltip>}
                  </React.Fragment>}
                  {doc.status == OrderDoc.STATUS_COMPLETE && <div className="badge badge-lg rounded-pill bg-success doc-item-badge mt-0" id={'doc-accepted-' + doc.id}>Accepted</div>}
                  {doc.isCapturedAsPdf && <i className="mdi mdi-file-pdf-outline ms-1 captured-as-pdf-icon" title="Document has been captured as pdf" />}
                  {doc.isCapturedAsPdf && <Button style={{ marginLeft: 'auto' }} color="secondary" className="square-icon-btn btn btn-danger" title="Remove Pdf file" onClick={() => {
                    setDocToRemovePdf(doc);
                    setIsRemovePdfConfirmationVisible(true);
                  }} disabled={isDeletingPdf}>
                    <i className="mdi mdi-trash-can-outline" />
                  </Button>}
                </div>
              </div>
              <div className="doc-item-mobile-body" accordionId={doc.id}>
                {!doc.isCapturedAsPdf && <div className="doc-pages">
                  <Gallery id="ink-docs" options={{ mainClass: "ink-sign-gallery" }}>
                    {Array.from({ length: doc.numOfPages }).map((page, index) => (
                      <React.Fragment key={index}>
                        <div className="doc-page-mobile-wrapper">
                          <DocPageMobile
                            docId={doc.id}
                            docName={doc.name}
                            isPageUploaded={!!doc.pages?.[index + 1]}
                            pageNum={index + 1}
                            finishedHandler={refreshHandler}
                            disabled={disabled}
                            pages={doc.pages}
                            setHasInProgressUploads={setHasInProgressUploads}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </Gallery>
                </div>}
              </div>
            </MobileView>

            {/* Remove Pdf Modal */}
            {isRemovePdfConfirmationVisible && <Confirmation
              confirmBtnText="Remove"
              reverseButtons={false}
              onConfirm={() => {
                setIsRemovePdfConfirmationVisible(false);
                removePdfFile();
              }}
              onCancel={() => {
                setIsRemovePdfConfirmationVisible(false);
                setDocToRemovePdf(null);
              }}>
              <span style={{ color: '#556EE6' }}>Are you sure you want to remove the pdf file for document &quot;{docToRemovePdf.name}&quot;?</span>
            </Confirmation>}
          </React.Fragment>
        </div>
      })}
    </React.Fragment>
  )
}

SectionNotaryDocs.propTypes = {
  order: PropTypes.object,
  docs: PropTypes.array,
  refreshHandler: PropTypes.func,
  disabled: PropTypes.bool,
  setHasInProgressUploads: PropTypes.func,
}

export default SectionNotaryDocs;