import React from "react";
import PropTypes from "prop-types";
import OrderNotary from "model/orderNotary";
import Order from "model/order";
import { CardHeader, CardTitle, Col, UncontrolledTooltip } from "reactstrap";
import { formatPhone } from "helpers/utilHelper";
import phoneIcon from "assets/images/mobile-phone-icon.svg";
import addressIcon from "assets/images/mobile-address-icon.svg";
import emailIcon from "assets/images/mobile-email-icon.png";


const OrderCardHeaderMobile = ({ order }) => {

  const virtualStatus = OrderNotary.getVirtualStatus(order.orderNotaryStatus);

  const isOrderCancelled = order?.status == Order.STATUS_CANCELLED;

  const getStatusName = () => isOrderCancelled ? 'Canceled' : OrderNotary.getVirtualStatusName(virtualStatus);
  const getStatusColor = () => isOrderCancelled ? 'danger' : OrderNotary.getVirtualStatusColor(virtualStatus);

  return (<div className={`mav-card-header p-0 ${!!order.orderSigner2FullName ? 'additional-signer' : ''}`}>
    {/* Customer Header */}
    <CardHeader className="header-customer">
      <div className="d-flex align-items-start">
        <Col xs={6} >
          <div className="d-flex align-items-center justify-content-between">
            <div className="order-number font-size-12 mb-2">#{order.id}  {!!order.unreadMessagesCount && <><span id={`unreadMessages${order.id}`} className="badge rounded-pill bg-danger font-size-10 ms-1">{order.unreadMessagesCount}</span><UncontrolledTooltip placement="top" target={`unreadMessages${order.id}`}>{order.unreadMessagesCount === 1 ? 'New message' : 'New messages'}</UncontrolledTooltip></>}</div>
            <span className={`badge badge-sm rounded-pill px-2 py-1 bg-${getStatusColor()}`}>
              {getStatusName()}
            </span>
          </div>
          <CardTitle className="mav-card-title font-weight-700">{order.orderSignerFullName}</CardTitle>
        </Col>
        <Col className="ms-2" xs={6}>
          <div className="contact-info">
            <div className="d-flex align-items-start">
              <img src={phoneIcon} alt="phone-icon" />
              <div className="word-wrap-break mb-2 ms-2">{formatPhone(order.orderSignerPhone)}</div>
            </div>
            <div className="d-flex align-items-start">
              <img className='header-section-icon' alt="icon" src={emailIcon} />
              <div className="word-wrap-break mb-2 ms-2">{order.orderSignerEmail}</div>
            </div>
            <div className="d-flex align-items-start">
              <img src={addressIcon} alt="address-icon" />
              <div className="mb-2 ms-2 w-100 pe-4 text-break">{order.orderSignerAddress}, {order.orderSignerState}, {order.orderSignerCity}, {order.orderSignerZip}{order.orderSignerAddress2 && `, ${order.orderSignerAddress2}`}</div>
            </div>
          </div>
        </Col>
      </div>
    </CardHeader>

    {/* Additional Signer Header */}
    {!!order.orderSigner2FullName && <CardHeader className="header-additional-signer">
      <div className="d-flex align-items-start">
        <Col xs={6}>
          <div className="d-flex justify-content-between align-center">
            <div className="mav-card-subtitle mb-2">Additional signer:</div>
          </div>
          <CardTitle className="mav-card-title font-weight-700">{order.orderSigner2FullName}</CardTitle>
        </Col>
        <Col className="ms-2" xs={6}>
          <div className="contact-info">
            <div className="d-flex align-items-start">
              <img src={phoneIcon} alt="phone-icon" />
              <div className="word-wrap-break mb-2 ms-2">{formatPhone(order.orderSigner2Phone)}</div>
            </div>
            <div className="d-flex align-items-start">
              <img className='header-section-icon' alt="icon" src={emailIcon} />
              <div className="word-wrap-break mb-2 ms-2">{order.orderSigner2Email}</div>
            </div>
            <div className="d-flex align-items-start">
              <img src={addressIcon} alt="address-icon" />
              <div className="mb-2 ms-2 w-100 pe-4 text-break">{order.orderSigner2Address}, {order.orderSigner2State}, {order.orderSigner2City}, {order.orderSigner2Zip}{order.orderSigner2Address2 && `, ${order.orderSigner2Address2}`}</div>
            </div>
          </div>
        </Col>
      </div>
    </CardHeader>}
  </div>)
};

OrderCardHeaderMobile.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderCardHeaderMobile;