import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardFooter, Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import SpinnerChase from "components/Shared/SpinnerChase";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import { getMessages, doMessagesCleanup } from "store/actions";
import UserAvatar from "components/Shared/UserAvatar";
import { getInitialsFromName } from "helpers/utilHelper";
import Message from "model/message";

const channelMap = Message.getChannelMap();

const MessageList = () => {
  const dispatch = useDispatch();

  /********** STATE **********/
  const { messages: rows, messagesError: rowsError, isLoadInProgress } = useSelector(state => state.Message.List);

  /********** EFFECTS **********/
  useEffect(() => {
    // Refresh messages on mount
    dispatch(getMessages());

    // Cleanup on unmount
    return () => {
      dispatch(doMessagesCleanup());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          {isLoadInProgress && <SpinnerChase className="sm dtable" />}
          {!!rowsError && (
            <Alert color="danger" className="fade show text-center">
              <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load messages
            </Alert>
          )}
          {!rowsError && rows.length > 0 ? (
            rows.map((row) => (
              <Card key={row.id} className="mb-3">
                <CardBody>
                  <div className="d-flex">
                    <div className="align-self-center me-3">
                      <UserAvatar
                        id={+row.channel}
                        initials={getInitialsFromName(channelMap[row.channel])}
                        size="md"
                      />
                    </div>
                    <div className="flex-grow-1 overflow-hidden my-auto">
                      <h5 className="mobile-card-row-name text-truncate text-black">
                        {Message.getChannelName(row.channel)}
                      </h5>
                      <div className="font-size-10 mb-2">
                        Date: {formatTimestamp(row.createdTs, formats.DATETIME)}
                      </div>
                      <div className="font-size-10 mb-2 message-parent"><span className="sender-message-name text-truncate">{row.senderName}</span><span className="message-ellipsis mx-3 message-content-mobile">{row.content}</span></div>
                      <div className="d-flex justify-content-between">
                        <div className="font-size-10">
                          Order ID: {row.orderId}
                        </div>
                        <div className="font-size-10 mx-3">Customer: {row.customerName}</div>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardFooter className="bg-white px-4 pb-3 pt-0">
                  <Link
                    to={route(routes.view_order_channel, [
                      row.orderId,
                      row.channel,
                    ])}
                    className="btn btn-primary w-100"
                  >
                    View Message
                  </Link>
                </CardFooter>
              </Card>
            ))
          ) : (
            !isLoadInProgress && <Card><p className="text-center mt-2 pt-2">No messages found</p></Card>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MessageList;