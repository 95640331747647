export default class Order {

  static STATUS_CANCELLED = -1;
  static STATUS_DRAFT = 0;
  static STATUS_PENDING_VID = 1;
  static STATUS_PENDING_E_SIGN = 2;
  static STATUS_PENDING_INK_SIGN = 3;
  static STATUS_READY_TO_SEAL = 4;
  static STATUS_COMPLETED = 5;

  static CUSTOMER_LOCATION_REMOTE = 1;
  static CUSTOMER_LOCATION_IN_STORE = 2;

  static CONTRACT_OPTION_VID = 1;
  static CONTRACT_OPTION_SIGNING_VID = 2;
  static CONTRACT_OPTION_SIGNING = 3;

  static DOC_DELIVERY_OPTION_UPLOAD = 1;
  static DOC_DELIVERY_OPTION_SHIPPING = 2;

  static SEAL_STATUS_QUEUED = 1;
  static SEAL_STATUS_FAILED = 2;
  static SEAL_STATUS_SEALED = 3;

  static CONTRACT_TYPE_ORIGINAL = 1;
  static CONTRACT_TYPE_RESIGN = 2;

  static ITEM_TO_COLLECT_CORPORATE_RESOLUTION = 1;
  static ITEM_TO_COLLECT_PROOF_OF_INCOME = 2;
  static ITEM_TO_COLLECT_PROOF_OF_INSURANCE = 3;
  static ITEM_TO_COLLECT_DRIVER_LICENSE_COPY = 4;

  static UI_GRID_VIEW = "grid";
  static UI_LIST_VIEW = "list";

  static SHIPPING_COMPANY_OTHER = 1;
  static SHIPPING_COMPANY_FEDEX = 2;
  static SHIPPING_COMPANY_UPS = 3;

  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_CANCELLED:
        return 'danger';
      case this.STATUS_DRAFT:
        return 'warning';
      case this.STATUS_NEW:
        return 'primary';
      case this.STATUS_PENDING_VID:
      case this.STATUS_PENDING_E_SIGN:
      case this.STATUS_PENDING_INK_SIGN:
      case this.STATUS_READY_TO_SEAL:
        return 'info';
      case this.STATUS_COMPLETED:
        return 'success';
      default:
        return 'dark';
    }
  }

  static getStatusMap() {
    return {
      [this.STATUS_CANCELLED]: 'Canceled',
      [this.STATUS_DRAFT]: 'Draft',
      [this.STATUS_PENDING_VID]: 'Pending VID',
      [this.STATUS_PENDING_E_SIGN]: 'Pending E-sign',
      [this.STATUS_PENDING_INK_SIGN]: 'Pending Ink-sign',
      [this.STATUS_READY_TO_SEAL]: 'Ready to Seal',
      [this.STATUS_COMPLETED]: 'Completed',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }

  static getCustomerLocations() {
    return {
      [this.CUSTOMER_LOCATION_REMOTE]: 'Remote',
      [this.CUSTOMER_LOCATION_IN_STORE]: 'In store',
    };
  }

  static getContractOptions() {
    return {
      [this.CONTRACT_OPTION_VID]: 'Identity Verification only',
      [this.CONTRACT_OPTION_SIGNING_VID]: 'Contract Signing with Identity Verification',
      [this.CONTRACT_OPTION_SIGNING]: 'Contract Signing only',
    };
  }

  static getContractTypeMap() {
    return {
      [this.CONTRACT_TYPE_ORIGINAL]: 'Original - Complete document package for signing',
      [this.CONTRACT_TYPE_RESIGN]: 'Resign - 1 or 2 documents that need resigning',
    };
  }

  static getContractTypeName(id) {
    return this.getContractTypeMap()[id];
  }

  static getItemToCollectMap() {
    return {
      [this.ITEM_TO_COLLECT_CORPORATE_RESOLUTION]: 'Corporate Resolution',
      [this.ITEM_TO_COLLECT_PROOF_OF_INCOME]: 'Proof of Income',
      [this.ITEM_TO_COLLECT_PROOF_OF_INSURANCE]: 'Proof of Insurance',
      [this.ITEM_TO_COLLECT_DRIVER_LICENSE_COPY]: "Copy of Driver License",
    };
  }

  static getItemToCollectName(id) {
    return this.getItemToCollectMap()[id];
  }

  static getDocDeliveryOptions() {
    return {
      [this.DOC_DELIVERY_OPTION_UPLOAD]: 'Upload',
      [this.DOC_DELIVERY_OPTION_SHIPPING]: 'Shipping',
    };
  }

  static getDocDeliveryOptionName(id) {
    return this.getDocDeliveryOptions()[id];
  }

  static getNotaryOptions() {
    return {
      1: 'Yes, I need a notary to assist the signing',
      0: 'No',
    };
  }

  static getRushOrderOptions() {
    return {
      1: 'Yes - striving for a completion time between 24 to 48 hours',
      0: 'No - striving for a completion time between 3 to 5 working days',
    };
  }

  static getShippingCompanyMap() {
    return {
      [this.SHIPPING_COMPANY_OTHER]: 'Other',
      [this.SHIPPING_COMPANY_FEDEX]: 'FedEx',
      [this.SHIPPING_COMPANY_UPS]: 'UPS',
    };
  }

  static getShippingCompanyName(id) {
    return this.getShippingCompanyMap()[id];
  }
}