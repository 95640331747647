import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col, Alert, Input } from "reactstrap";
import { getDtFooterRowCount, getSharedTableOptions, getSharedPaginationOptions } from "helpers/utilHelper";
import { doStatementDtCleanup, getStatementDt } from "store/actions";
import Filters from "./Filters";
import { omit } from "lodash";
import config from "config";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import { useDebounce } from "hooks/debounce";

const DataTable = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const { statements: rows, statementsError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.Statement.Dt);
  const filters = useSelector(state => state.Statement.DtFilters);
  const [searchTerm, setSearchTerm] = useState("");

  // Debounce the value of the search
  const debouncedSearch = useDebounce(searchTerm, config.DATA_TABLE_SEARCH_DELAY);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doStatementDtCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // refresh the list data based on the new filters
    dispatch(getStatementDt({
      ...listParams,
      // '_set' is a special flag we use to know if the default filter values have been initialized
      // we do not want that passed to back-end
      filters: omit(filters, '_set'),
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
  }, [filters]);

  // search is done manually, due to debouncing
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    dispatch(getStatementDt({
      ...listParams,
      page: 1,
      search: searchTerm,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [debouncedSearch]);


  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, etc)
  const handleTableChange = (type, newState) => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    // we want to ingore this event before the filters are initialized
    // therefore avoiding a duplicate call to the backend
    if (filters.hasOwnProperty("_set") && !filters._set) {
      return;
    }
    // refresh the list data based on the new table params
    dispatch(getStatementDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={getColumns(listParams.pageSize, listParams.page)}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Input type="text" onChange={e => setSearchTerm(e.target.value)} placeholder="Search" />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        <Filters />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No statements found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load statements
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const getColumns = (pageSize, page) => ([{
  dataField: 'idx',
  text: 'No.',
  formatter: (cellContent, row, rowIndex) => pageSize * (page - 1) + rowIndex + 1,
}, {
  dataField: "id",
  text: "Statement ID",
  // eslint-disable-next-line react/display-name
  formatter: cellContent => <Link to={route(routes.view_statement, cellContent)}>{cellContent}</Link>,
}, {
  dataField: "orderId",
  text: "Order ID",
}, {
  dataField: "createdTs",
  text: "Date",
  formatter: cellContent => formatTimestamp(cellContent, formats.LONG_DATE),
}, {
  dataField: "customerName",
  text: "Customer Name",
}, {
  dataField: "amount",
  text: "Amount",
  formatter: cellContent => `$${cellContent}`,
}, {
  dataField: "paymentId",
  text: "Status",
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent ? <span className="badge badge-lg rounded-pill bg-success">Paid</span> : <span className="badge badge-lg rounded-pill bg-warning">Pending</span>,
}]);

export default DataTable;
