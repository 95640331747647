import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, Button, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { useProfile } from "context/profile";
import { perms, useAccess } from "context/access";
import { getYesNoOptions } from "helpers/utilHelper";
import { useSelector } from "react-redux";
import Credential from "model/notaryCredential";

const ViewCredentialsDetails = ({ isRefreshing, toggleEditMode }) => {

  const { notary } = useProfile();
  const { credentials } = useSelector(state => state.Credential.List);

  const commissionCertificate = Object.values(credentials).find(c => c.type === Credential.TYPE_COMMISSION_CERTIFICATE);

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Additional Credential Details</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            {iAmGranted(perms.edit_notaries) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
              <i className="mdi mdi-pencil me-1" />Edit
            </Button>}
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Can You Print Digital Docs</td>
            <td className="section-row-value ps-4">{getYesNoOptions().find(o => o.value == notary.canPrintDigitalDocs)?.label || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">EDocs</td>
            <td className="section-row-value ps-4">{getYesNoOptions().find(o => o.value == notary.canSignElectronically)?.label || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Commission State</td>
            <td className="section-row-value ps-4">{commissionCertificate?.commissionState || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Commission in another state?</td>
            <td className="section-row-value ps-4">{commissionCertificate?.commissionState2 || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label no-border">Counties Covered</td>
            <td className="section-row-value ps-4 no-border">{commissionCertificate?.countiesCovered || "---"}</td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
    {isRefreshing && <CardPreloader />}
  </Card>)
}

ViewCredentialsDetails.propTypes = {
  isRefreshing: PropTypes.bool,
  toggleEditMode: PropTypes.func,
};

export default ViewCredentialsDetails;