import { combineReducers } from "redux";
import {
  GET_CREDENTIAL_LIST,
  GET_CREDENTIAL_LIST_OK,
  GET_CREDENTIAL_LIST_ERR,
  DO_CREDENTIAL_LIST_CLEANUP,

  DELETE_CREDENTIAL_LIST_ITEM,
  DELETE_CREDENTIAL_LIST_ITEM_OK,
  DELETE_CREDENTIAL_LIST_ITEM_ERR,

  UPLOAD_CREDENTIAL,
  UPLOAD_CREDENTIAL_OK,
  UPLOAD_CREDENTIAL_ERR,
  DO_CREDENTIAL_FORM_CLEANUP,
} from "./actionTypes";
import Credential from "model/notaryCredential";

/********** LIST **********/

const defaultListState = {
  credentialIds: [],
  credentials: [],
  commissionCertificate: 0,
  eoInsurance: 0,
  driverLicense: 0,
  credentialsError: null,
  isLoadInProgress: false,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    // get list
    case GET_CREDENTIAL_LIST:
      state = {
        ...state,
        credentialsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_CREDENTIAL_LIST_OK:
      const ids = [];
      const docs = action.payload.response.credentials.reduce((prev, curr) => {
        ids.push(curr.id);
        return { ...prev, [curr.id]: curr };
      }, {});

      // mandatory documents count
      const commissionCertificate = action.payload.response.credentials.filter(doc => doc.type === Credential.TYPE_COMMISSION_CERTIFICATE).length;
      const eoInsurance = action.payload.response.credentials.filter(doc => doc.type === Credential.TYPE_EO_INSURANCE).length;
      const driverLicense = action.payload.response.credentials.filter(doc => doc.type === Credential.TYPE_DRIVER_LICENSE_NUMBER).length;

      state = {
        ...state,
        credentialIds: ids,
        credentials: docs,
        commissionCertificate,
        eoInsurance,
        driverLicense,
        isLoadInProgress: false,
      };
      break
    case GET_CREDENTIAL_LIST_ERR:
      state = {
        ...state,
        credentials: [],
        credentialsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // delete doc
    case DELETE_CREDENTIAL_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.credentials[docId];
      doc.isDeleted = null;
      doc.isDeleteInProgress = true;
      doc.deleteError = null;
      state = {
        ...state,
        credentials: {
          ...state.credentials,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_CREDENTIAL_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.credentials[docId];
      doc.isDeleted = true;
      doc.isDeleteInProgress = false;
      state = {
        ...state,
        credentials: {
          ...state.credentials,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_CREDENTIAL_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.credentials[docId];
      doc.isDeleted = false;
      doc.isDeleteInProgress = false;
      doc.deleteError = action.payload.error;
      state = {
        ...state,
        credentials: {
          ...state.credentials,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // cleanup
    case DO_CREDENTIAL_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

/********** FORM **********/

const defaultFormState = {
  doc: null,
  uploaded: null,
  uploadError: null,
  isUploadInProgress: false,
}

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case UPLOAD_CREDENTIAL:
      state = {
        ...state,
        uploaded: null,
        uploadError: null,
        isUploadInProgress: true,
      };
      break
    case UPLOAD_CREDENTIAL_OK:
      state = {
        ...state,
        doc: {
          ...state.doc,
          id: action.payload.response.id,
        },
        uploaded: true,
        isUploadInProgress: false,
      };
      break
    case UPLOAD_CREDENTIAL_ERR:
      state = {
        ...state,
        uploaded: false,
        uploadError: action.payload.error,
        isUploadInProgress: false,
      };
      break
    case DO_CREDENTIAL_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

export default combineReducers({
  List,
  Form,
});