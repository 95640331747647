import { LocalException } from './errorHelper';
import { isObject, valueIsEmpty } from './utilHelper';

export const routes = {
  home: '/',

  list_notifications: '/notifications',
  view_notification: '/notification/:id',

  list_messages: '/messages',

  setup_profile_terms: '/profile/setup/terms',
  setup_profile_billing: '/profile/setup/billing',
  setup_profile_shipping: '/profile/setup/shipping',
  setup_profile_contact: '/profile/setup/contact',
  setup_profile_credentials: '/profile/setup/credentials',
  setup_profile_experience: '/profile/setup/experience',
  setup_profile_area: '/profile/setup/area',

  view_profile: '/profile',

  list_notary_bids: '/notary-bids',
  view_notary_bid: '/notary-bid/:id',

  list_orders: '/orders',
  view_order: '/order/:id',
  view_order_messages: '/order/:id/messages/:channel',
  view_order_channel: '/order/:id/channels/:channel/',
  view_order_flow: '/order/:id/flow',
  new_order_doc: '/order/:id/doc',

  list_statements: '/statements',
  view_statement: '/statement/:id',

  view_documents: '/documents',
  view_terms: '/terms',

  meet_scheduler: '/order/:id/meet/scheduler',

  list_help: '/help',
};

export const route = (route, params, query) => {
  if (!Object.values(routes).includes(route)) {
    throw new LocalException(`Unknown route '${route}'`);
  }
  if (!valueIsEmpty(params)) {
    if (Array.isArray(params)) {
      params.forEach(param => {
        // handle optional params
        if (valueIsEmpty(param)) {
          route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
        } else {
          route = route.replace(new RegExp(':([a-zA-Z]+)'), param);
        }
      });
    } else if (isObject(params)) {
      for (let key in params) {
        // handle optional params
        if (valueIsEmpty(params[key])) {
          route = route.replace(new RegExp(`/:${key}`), '');
        } else {
          route = route.replace(new RegExp(`:${key}`), params[key]);
        }
      }
    } else {
      // handle optional params
      if (valueIsEmpty(params)) {
        route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
      } else {
        route = route.replace(new RegExp(':([a-zA-Z]+)'), params);
      }
    }
  }
  if (query) {
    if (isObject(query)) {
      let parts = [];
      for (let key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach(value => {
            parts.push(`${key}[]=${value}`);
          });
        } else if (isObject(query[key])) {
          for (let key2 in query[key]) {
            parts.push(`${key}[${key2}]=${query[key][key2]}`);
          }
        } else {
          parts.push(`${key}=${query[key]}`);
        }
      }
      route += `?${parts.join('&')}`;
    } else {
      route += `?${query}`;
    }
  }
  return route;
};