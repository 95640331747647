import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import uploadPdf from "assets/images/upload-pdf-icon.svg";
import Confirmation from "components/Shared/Confirmation";
import { showError } from "helpers/utilHelper";
import { uploadOrderDocPdf } from "helpers/backendHelper";

const UploadPdf = ({ doc, finishedHandler, disabled }) => {

  const fileInput = useRef(null);

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const pdfFileSelected = async e => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("pdfFile", e.target.files[0]);
    setIsUploading(true);
    try {
      await uploadOrderDocPdf(doc.id, formData);
      finishedHandler();
    } catch (err) {
      showError("Unable to upload pdf file");
    } finally {
      setIsUploading(false);
    }
  }

  return <React.Fragment>
    <div className="doc-page capture-doc-as-pdf" onClick={() => !disabled ? setIsConfirmationVisible(true) : null}>
      <div className="doc-page-img">
        {!isUploading && <img src={uploadPdf} className="noimg" />}
        {isUploading && <i className="mdi mdi-spin mdi-loading"></i>}
      </div>
      <Button className="w-100" color="primary" disabled={disabled}>Upload PDF</Button>
    </div>
    <input id={'fileInput-' + doc.id + '-pdf'} type="file" accept="application/pdf" onChange={pdfFileSelected} className="d-none" ref={fileInput} />
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Upload"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        fileInput.current.click();
      }}
      reverseButtons={false}
      onCancel={() => {
        setIsConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>For a document you can either upload pictures of each page, or upload a PDF with the entire document. If you uploaded some pages of the document, and now upload the document in PDF format, the previously added pages will be removed. </span>
    </Confirmation>}
  </React.Fragment>
}

UploadPdf.propTypes = {
  doc: PropTypes.object.isRequired,
  finishedHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default UploadPdf;