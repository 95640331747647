import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { printDocuments } from 'helpers/backendHelper';
import { Button } from "reactstrap";
import Lottie from 'lottie-react';
import prepareAnimationData from 'assets/animations/preparing-print-document.json';
import downloadAnimationData from 'assets/animations/download-print-document.json';
import { showError } from 'helpers/utilHelper';
import { timestamp } from 'helpers/dateHelper';

const PrintAllDocs = ({ orderNotaryId }) => {

  const [isPrepareInProgress, setIsPrepareInProgress] = useState(false);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const [percentCompleted, setPercentCompleted] = useState(0);

  const lottieRef = useRef();

  const printAllDocs = () => {
    setIsPrepareInProgress(true)
    printDocuments(orderNotaryId, {
      responseType: 'arraybuffer', onDownloadProgress: (progressEvent) => {
        setIsPrepareInProgress(false);
        setIsDownloadInProgress(true);
        setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `NotaryDocuments-${timestamp()}.pdf`);
      document.body.appendChild(link);
      link.click();
    }).catch(ex => {
      showError("Unable download docs for print");
    }).finally(() => {
      setIsPrepareInProgress(false)
      setIsDownloadInProgress(false)
    })
    return;
  }

  useEffect(() => {
    if (percentCompleted && lottieRef && lottieRef.current) {
      const duration = lottieRef.current.getDuration(true);
      const nextFrame = parseInt(percentCompleted / 100 * duration);
      lottieRef.current.goToAndStop(nextFrame, true);
    }
  }, [percentCompleted]);

  return <>
    <Button color="primary" className="mav-card-action-btn mt-4 justify-content-center d-flex btn-print" onClick={printAllDocs}>
      <div className="d-flex align-items-center">
        <i className="bx bx-download font-size-18 me-2" />
        Print all documents
      </div>
    </Button>
    {(isPrepareInProgress || isDownloadInProgress) && <div className="lottie-container">
      <div className="overlay"></div>
      <div className="lottie-parent">
        {isPrepareInProgress && <>
          <Lottie
            animationData={prepareAnimationData}
            style={{
              height: 200,
              width: 200
            }}
            className="lottie-obj"
          />
          <div className="animation-title">Preparing the document</div>
          <div className="animation-description">This might take several minutes</div>
        </>
        }
        {isDownloadInProgress && <>
          <Lottie
            lottieRef={lottieRef}
            animationData={downloadAnimationData}
            style={{
              height: 200,
              width: 200
            }}
            className="lottie-obj"
          />
          <div className="animation-title">Downloading the document</div>
          <div className="animation-description">This might take several minutes</div>
        </>
        }
      </div>
    </div>}
  </>
}

PrintAllDocs.propTypes = {
  orderNotaryId: PropTypes.number,
}

export default PrintAllDocs;