import React from "react";
import PropTypes from "prop-types"
import { Formik } from "formik";
import { nullsToEmptyStrings } from "helpers/utilHelper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useProfile } from "context/profile";
import { saveProfileArea, saveProfileShipping } from "store/actions";

const FormArea = ({ children }) => {

  const dispatch = useDispatch();
  const { notary } = useProfile();

  /********** FORM CONFIG **********/

  // default values of the form fields
  const formInitialValues = {
    distanceServed: "",
    // load already saved profile info into the form
    ...nullsToEmptyStrings(notary),
  };

  // we are using Formik higher-order component here in order to be able to access the Formik context in child components
  // this helps us split the code in smaller components (one for form definition - this one, and one for the content)
  // decoupling the form content from the form definition allows us to use the same components in two different places: the profile setup and edit
  // in case of differences between setup and edit, we can use a `isSetupRoute` prop to flag from where we render the component
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={values => dispatch(saveProfileArea(values))}
    >
      {children}
    </Formik>)
}

const validationSchema = Yup.object({
  distanceServed: Yup.number().required("Field is required"),
});

FormArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default FormArea;