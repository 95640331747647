import { all, fork } from 'redux-saga/effects';

import AuthSaga from './auth/saga';
import LayoutSaga from './layout/saga';
import NotificationSaga from './notification/saga';
import ProfileSaga from './profile/saga';
import CredentialSaga from './credential/saga';
import MessagesSaga from './messages/saga';
import OrderSaga from './order/saga';
import NotaryBidSaga from './notaryBid/saga';
import StatementSaga from './statement/saga';

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(NotificationSaga),
    fork(ProfileSaga),
    fork(CredentialSaga),
    fork(MessagesSaga),
    fork(OrderSaga),
    fork(NotaryBidSaga),
    fork(StatementSaga),
  ])
}
