import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { Button, Card, Col, Container, Input, Label, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from "reactstrap";
import { formats, formatTimestamp } from "helpers/dateHelper";
import { useDispatch, useSelector } from "react-redux";
import { changeNotificationsPage, deleteNotifications, doNotificationDtCleanup, getNotificationDt, markNotificationsRead, markNotificationsUnread, getNotificationList } from "store/notification/actions";
import { sanitizeHtml, showError, showSuccess } from "helpers/utilHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import socketEvent from "constants/socketEvent";
import { useSocketOn } from "hooks/socket";
import Confirmation from "components/Shared/Confirmation";
import { route, routes } from "helpers/routeHelper";
import Select from "react-select";
import { BrowserView, MobileView } from "react-device-detect";

const List = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { notifications, totalCount, notificationsError, isLoadInProgress, listParams, isMarkInProgress, marked, isDeleteInProgress, deleted } = useSelector(state => state.Notification.Dt);

  const [moreMenu, setMoreMenu] = useState(false);

  const [selected, setSelected] = useState([]); // ids of selected rows

  const [allSelected, setAllSelected] = useState(false);

  const [itemPerPage, setItemPerPage] = useState(listParams.pageSize);

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const itemsPerPage = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
    {
      label: "200",
      value: 200,
    }
  ];
  /********** HANDLERS **********/

  const handleCheckboxChange = id => (event) => {
    // select an item
    if (event.target.checked) setSelected([...selected, id]);
    // or deselect it
    else {
      const indexToRemove = selected.findIndex(item => item === id);
      setSelected([...selected.slice(0, indexToRemove), ...selected.slice(indexToRemove + 1)]);
    }
  };

  const handleAllCheckboxChange = ({ target: { checked } }) => {
    setAllSelected(checked);
    const currentPageIds = notifications.map(notification => notification.id);
    // keep the selections from other pages and add the ones from the current page as well
    if (checked) setSelected([...selected, ...currentPageIds]);
    // or remove only the selections from the current page
    else setSelected(selected.filter(item => !currentPageIds.includes(item)));
  };

  const handleNextPage = () => {
    setAllSelected(false); // reset `all` checkbox
    dispatch(changeNotificationsPage(listParams.page + 1));
  };

  const handlePrevPage = () => {
    setAllSelected(false); // reset `all` checkbox
    dispatch(changeNotificationsPage(listParams.page - 1));
  };

  const handleMarkRead = () => {
    if (selected.length) {
      dispatch(markNotificationsRead(selected));
      setSelected([]); // uncheck selected inputs
      setAllSelected(false); // reset `all` checkbox
    }
  };

  const handleMarkUnread = () => {
    if (selected.length) {
      dispatch(markNotificationsUnread(selected));
      setSelected([]); // uncheck selected inputs
      setAllSelected(false); // reset `all` checkbox
    }
  };

  const removeNotifications = () => {
    if (selected.length) {
      dispatch(deleteNotifications(selected));
      setSelected([]); // uncheck selected inputs
      setAllSelected(false); // reset `all` checkbox
    }
  }

  /********** SOCKET **********/

  const onNotificationReceived = useCallback(() => {
    dispatch(getNotificationDt(listParams));
  }, [listParams]);

  // listen for changes on notifications
  useSocketOn(socketEvent.notificationReceived, onNotificationReceived);

  /********** EFFECTS **********/

  // state cleanup on component unmount
  useEffect(() => {
    return () => {
      dispatch(doNotificationDtCleanup());
    }
  }, []);

  // runs whenever page changes (including component mount)
  useEffect(() => {
    if (itemPerPage >= totalCount) {
      dispatch(getNotificationDt({
        ...listParams,
        pageSize: itemPerPage,
        page: 1,
      }));
    } else {
      dispatch(getNotificationDt({
        ...listParams,
        pageSize: itemPerPage,
      }));
    }
  }, [listParams.page, listParams.pageSize, itemPerPage]);

  // runs whenever the `marked` flag changes
  // which happens after a mark-notification attempt
  useEffect(() => {
    if (marked === true) {
      dispatch(getNotificationList()); // refresh the list in the header
    } else if (marked === false) {
      showError("Unable to mark notifications");
    }
  }, [marked]);

  // runs whenever the `deleted` flag changes
  // which happens after a delete-notification attempt
  useEffect(() => {
    if (deleted === true) {
      showSuccess("Notifications have been deleted");
      dispatch(getNotificationDt(listParams));
      dispatch(getNotificationList()); // refresh the list in the header
    } else if (deleted === false) {
      showError("Unable to delete notifications");
    }
  }, [deleted]);

  return (
    <div className="page-content">
      <MetaTitle>Notifications</MetaTitle>
      <Container fluid className='notification-list'>
        <Breadcrumbs title="NOTIFICATIONS" />
        <Row>
          <Col xs="12">
            <div className="mb-3">
              <Card>
                <div className="btn-toolbar p-3" role="toolbar">
                  <div className="btn-group me-2 mb-2 mb-sm-0">
                    <label className="btn btn-primary mb-0">
                      <Input type="checkbox" checked={allSelected} onChange={handleAllCheckboxChange} />
                    </label>
                    <Button type="button" color="primary" onClick={() => { setIsConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                      {isDeleteInProgress ? <i className="mdi mdi-spin mdi-loading me-1" /> : <i className="far fa-trash-alt" />}
                    </Button>
                  </div>
                  <Dropdown isOpen={moreMenu} toggle={() => { setMoreMenu(!moreMenu); }} className="btn-group me-2 mb-2 mb-sm-0">
                    <DropdownToggle className="btn btn-primary dropdown-toggle" tag="i">
                      {isMarkInProgress ? <i className="mdi mdi-spin mdi-loading me-1" /> : <span className="font-size-13" style={{ fontStyle: "normal" }}>More</span>}
                      <i className="mdi mdi-chevron-down ms-1" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={handleMarkRead}>Mark as Read</DropdownItem>
                      <DropdownItem to="#" onClick={handleMarkUnread}>Mark as Unread</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <React.Fragment>
                  {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                  <BrowserView>
                    <ul className="message-list overflow-hidden">
                      {!isLoadInProgress && notifications.map(({ isRead, id, subject, createdTs, message }, index) => (
                        <li key={index} className={isRead ? "" : "unread"}>
                          <Row className="d-flex justify-content-between">
                            <Col xs={7} md={10} className="col-notif-1">
                              <Row>
                                <Col>
                                  <div className="checkbox-wrapper-mail">
                                    <Input type="checkbox" id={id} checked={selected.includes(id)} onChange={handleCheckboxChange(id)} />
                                    <Label htmlFor={id} className="toggle" />
                                  </div>
                                </Col>
                                <Col>
                                  <Link to={route(routes.view_notification, id)} className="title">
                                    {subject}
                                  </Link>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={5} md={2} className="col-notif-2">
                              <div className="date">{formatTimestamp(createdTs, formats.DATETIME)}</div>
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </BrowserView>
                  <MobileView>
                    {!isLoadInProgress && notifications.map(({ isRead, id, subject, createdTs, message }, index) => (
                      <div key={index} className={`mobile-notification-row ${selected.includes(id) ? 'active' : ''} ${isRead ? '' : 'unread'}`}>
                        <div className={`d-flex align-items-center ${isRead ? "" : "unread"}`}>
                          <div className="col-1">
                            <div className="checkbox-wrapper-mail">
                              <Input type="checkbox" id={id} checked={selected.includes(id)} onChange={handleCheckboxChange(id)} />
                              <Label htmlFor={id} className="toggle" />
                            </div>
                          </div>
                          <div className="col-11">
                            <Link to={route(routes.view_notification, id)}>
                              <div className="title">{subject}</div>
                            </Link>
                            <div className="message" dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }} />
                            <div className="date">{formatTimestamp(createdTs, formats.DATETIME)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </MobileView>
                  {!isLoadInProgress && !notificationsError && !notifications.length && <li className="text-center">No notifications found</li>}
                  {!isLoadInProgress && notificationsError && <Alert color="danger" className="fade show text-center mb-0">
                    <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load notifications
                  </Alert>}
                </React.Fragment>
              </Card>
              <Row>
                <BrowserView>
                  <Col xs="2">
                    <Select
                      options={itemsPerPage}
                      onChange={selected => setItemPerPage(selected.value)}
                      value={itemsPerPage.find(selected => selected.value === itemPerPage)}
                    />
                  </Col>
                  <Col xs="10">
                    <Row>
                      <Col xs="11" className='text-end align-self-center'>{`Showing ${listParams.pageSize * (listParams.page - 1) + 1} - ${Math.min(listParams.page * listParams.pageSize, totalCount)} of ${(totalCount).toLocaleString()}`}</Col>
                      <Col xs="1">
                        <div className="btn-group float-end">
                          <Button type="button" color="success" size="sm" onClick={handlePrevPage} disabled={listParams.page === 1}>
                            <i className="fa fa-chevron-left" />
                          </Button>
                          <Button type="button" color="success" size="sm" onClick={handleNextPage} disabled={totalCount <= listParams.page * listParams.pageSize}>
                            <i className="fa fa-chevron-right" />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </BrowserView>

                <MobileView className="notification-list-footer">
                  <Row>
                    <Col xs="5">
                      <Select
                        options={itemsPerPage}
                        onChange={selected => setItemPerPage(selected.value)}
                        value={itemsPerPage.find(selected => selected.value === itemPerPage)}
                      />
                    </Col>
                    <Col xs="5">
                      <h6 className="pagination-label">{`Showing ${listParams.pageSize * (listParams.page - 1) + 1} - ${Math.min(listParams.page * listParams.pageSize, totalCount)} of ${(totalCount).toLocaleString()}`}</h6>
                    </Col>
                    <Col xs="2">
                      <div className="btn-group float-end">
                        <Button type="button" color="success" size="sm" onClick={handlePrevPage} disabled={listParams.page === 1}>
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button type="button" color="success" size="sm" onClick={handleNextPage} disabled={totalCount <= listParams.page * listParams.pageSize}>
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </MobileView>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {isConfirmationVisible && <Confirmation
        confirmBtnText="Delete"
        onConfirm={() => {
          setIsConfirmationVisible(false);
          removeNotifications();
        }}
        reverseButtons={false}
        onCancel={() => setIsConfirmationVisible(false)}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to delete selected notifications?</span>
      </Confirmation>}
    </div>
  );
};

export default List
