import React from "react";
import PropTypes from "prop-types"
import { Formik } from "formik";
import { nullsToEmptyStrings } from "helpers/utilHelper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useProfile } from "context/profile";
import { saveProfileShipping, saveProfileShippingArea } from "store/actions";

const FormShipping = ({ children, isSetupRoute }) => {

  const dispatch = useDispatch();
  const { notary } = useProfile();

  /********** FORM CONFIG **********/

  // default values of the form fields
  const formInitialValues = {
    distanceServed: "",
    shippingAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingZip: "",
    shippingLatitude: "", // field calculated by Google API after selecting an address
    shippingLongitude: "", // field calculated by Google API after selecting an address
    // load already saved profile info into the form
    ...nullsToEmptyStrings(notary),
  };

  // we are using Formik higher-order component here in order to be able to access the Formik context in child components
  // this helps us split the code in smaller components (one for form definition - this one, and one for the content)
  // decoupling the form content from the form definition allows us to use the same components in two different places: the profile setup and edit
  // in case of differences between setup and edit, we can use a `isSetupRoute` prop to flag from where we render the component
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        const isSameAsBillingAddress = JSON.parse(localStorage.getItem("isSameAddress"));

        // clear `use same address` checkbox if the shipping address has been changed with a different one
        if (isSameAsBillingAddress && values.shippingAddress !== values.billingAddress) {
          localStorage.setItem("isSameAddress", false);
        }

        if (isSetupRoute) {
          dispatch(saveProfileShipping(values));
        } else {
          dispatch(saveProfileShippingArea(values));
        }
      }}
    >
      {children}
    </Formik>)
}

const validationSchema = (isSetupRoute) => Yup.object({
  shippingAddress: Yup.string().trim().required("Field is required"),
  shippingCity: Yup.string().trim().required("Field is required"),
  shippingState: Yup.string().trim().required("Field is required"),
  shippingZip: Yup.string().trim().required("Field is required"),
  shippingLatitude: Yup.number().required("Field is required"),
  shippingLongitude: Yup.number().required("Field is required"),
  distanceServed: Yup.number().when([], {
    is: () => !isSetupRoute,
    otherwise: Yup.number().required("Field is required")
  })
});

FormShipping.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isSetupRoute: PropTypes.bool
}

export default FormShipping;