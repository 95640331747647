import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Label, FormFeedback } from "reactstrap";
import { showError, showSuccess, distanceServedOptions, getMeters } from "helpers/utilHelper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { resetSaveProfileActionFlag } from "store/actions";
import GoogleMapsLocation from "components/Shared/GoogleMapsLocation";
import Select from "react-select";
import { useFormikContext } from "formik";

const FormContentArea = props => {

  const dispatch = useDispatch();
  const { notary, refreshProfile } = useProfile();

  // consume formik instance from context
  const formik = useFormikContext();

  // saved - TRUE if a save was attempted and was successful, FALSE if it failed, NULL if no save was attempted yet
  const { saved } = useSelector(state => state.Profile.Form);

  /********** EFFECTS **********/

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Delivery info saved");
      refreshProfile();
    } else if (saved === false) {
      showError("Unable to save delivery info");
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag("saved"));
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <Form>
    <Row className="mb-4 align-items-center">
      <Label className="col-sm-4 col-form-label word-wrap-normal">Distance Willing to Travel*</Label>
      <Col sm={12}>
        <Select
          classNamePrefix="select2-selection"
          name="distanceServed"
          options={distanceServedOptions}
          onChange={selected => formik.setFieldValue("distanceServed", selected.value)}
          onFocus={onFieldFocused}
          value={distanceServedOptions.find(option => option.value === formik.values.distanceServed)}
          className={!!formik.errors.distanceServed && "is-invalid"} />
        {!!formik.errors.distanceServed && <FormFeedback type="invalid">{formik.errors.distanceServed}</FormFeedback>}
      </Col>
    </Row>
    {props.showMap && <Row>
      <Col>
        <GoogleMapsLocation
          google={props.google}
          position={{ lat: notary.shippingLatitude, lng: notary.shippingLongitude }}
          address={notary.shippingAddress}
          radius={getMeters(formik.values.distanceServed)}
        />
      </Col>
    </Row>}
  </Form>
}

FormContentArea.propTypes = {
  google: PropTypes.object,
  showMap: PropTypes.bool,
}

export default FormContentArea;